import React from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';
import features from '../data/features.json';

const { seo, header, footer, cta } = data;
const { site, pageTitle, sections } = features;

const FeaturesPage = () => (
  <div>
    <SEO title={site.title} seo={seo} />
    <Header header={header} />
    <main>
      <Layout className="background--firework section--top-l">
        <Heading tag="h1" headingClass="heading--special">
          {setHTML(pageTitle)}
        </Heading>
        {sections.map((section, index) => {
          return (
            <div id={section.sectionId} key={`feature-section-${index}`}>
              <Heading
                tag="h2"
                headingClass="heading--special heading--xxl text--secondary"
              >
                {setHTML(section.sectionTitle)}
              </Heading>
              <div className="grid">
                {section.sectionContent.map((content, contentIndex) => {
                  return (
                    <div
                      className="grid__col grid--4-col"
                      key={`feature-content-${contentIndex}`}
                    >
                      <Heading tag="h3" headingClass="heading--m">
                        {setHTML(content.title)}
                      </Heading>
                      <p>{setHTML(content.content)}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Layout>
      <Cta cta={cta} />
    </main>
    <Footer footer={footer} />
  </div>
);

export default FeaturesPage;
